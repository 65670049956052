<template>
  <b-modal :title="`Policy ${(index + 1)} Import`" size="md" v-model="active">
    <b-form-group>
      <label for="insuranceCompany">Import File Type</label>
      <b-select id="insuranceCompany" :options="importOptions" v-model="importCompany"></b-select>
    </b-form-group>
    <b-form-group v-if="importCompany">
      <b-form-file id="fileInput" v-model="csvFile" accept=".csv" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>
    </b-form-group>
    <b-alert :show="(errorMessage != null)" variant="danger">{{ errorMessage }}</b-alert>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid || uploading" variant="success" @click="upload()">
        Upload
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/axios'

export default {
  name: 'ImportModal',
  props: [ 'index' ],
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    })
  },
  data () {
    return {
      active: false,
      policy: {},
      errorMessage: null,
      csvFile: null,
      uploading: false,
      importCompany: null,
      importOptions: [
        { value: null, text: 'Select One...' },
        { value: 'mtl', text: 'Mutual Trust Life (CSV)' }
      ],
    }
  },
  methods: {
    close () {
      this.active = false
    },
    show (policy) {
      this.active = true
      if (policy) {
        this.policy = { ...policy }
      }
    },
    upload () {
      this.uploading = true
      const formData = new FormData()
      formData.append('csvFile', this.csvFile)
      axios.put(`/v1/policies/${this.policy.id}/upload`, formData)
      .then(response => {
        this.$snotify.success('Uploaded')
        this.uploading = false
        this.$emit('uploaded', response.data)
        this.close()
      })
      .catch((err) => {
        this.$snotify.error('Error Uploading')
      })
    }
  },
  created () {
    this.$bus.$on(`modal:importPolicy-${this.index}`, (policy) => {
      this.show(policy);
      this.errorMessage = null
      this.$nextTick(() => { this.$v.$reset() })
    });
  },
  validations: {
  }
}
</script>
