<template>
  <b-card>
    <template slot="header">
      <b-row>
        <b-col sm="6">
          Policy {{ (index + 1) }}<b-badge class="ml-2" variant="primary">{{ policy.existing ? 'Existing' : 'New' }}</b-badge>
        </b-col>
        <b-col sm="6" class="text-right">
          <!-- <b-btn size="sm" variant="primary" @click="importPolicy"><i class="fa fa-file mr-2"></i>Import</b-btn> -->
          <b-btn v-if="policy.existing" size="sm" variant="danger" @click="deletePolicy(policy)">Unlink</b-btn>
          <b-btn v-if="!policy.existing" size="sm" variant="danger" @click="deletePolicy(policy)">Delete</b-btn>
        </b-col>
      </b-row>
    </template>
    <b-row>
      <b-col sm="8">
        <b-row class="form-group">
          <b-col sm="3">
            <label for="contact">Policy Name</label>
            <b-form-input type="text" v-model="policy.name" v-on:blur.native="save()" @input="touch($v.policy.name)"></b-form-input>
          </b-col>
          <b-col sm="3">
            <label for="contact">Insured Contact</label>
            <b-form-select :options="contactOptions" v-model="policy.contactId" @input="touch($v.policy.contactId)"></b-form-select>
          </b-col>
          <b-col sm="3">
            <label for="company">Company</label>
            <b-form-select :options="insuranceCompanyOptions" v-model="policy.insuranceCompanyId" @input="touch($v.policy.insuranceCompanyId)"></b-form-select>
          </b-col>
          <b-col sm="3">
            <label for="loanAmount">Start Date</label>
            <flat-pickr class="form-control date" v-model.trim="policy.startedAt" :config="dateConfig" placeholder="Start Date" :state="state($v.policy.startedAt)" @input="touch($v.policy.startedAt)"></flat-pickr>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="2">
            <label for="total_premium">Premium</label>
            <b-form-input type="text" class="text-right" placeholder="e.g. 10000" v-model="policy.annualPremium" v-on:blur.native="updateBase(); save()" :state="state($v.policy.annualPremium)" @input="touch($v.policy.annualPremium)"></b-form-input>
          </b-col>
          <b-col sm="2">
            <label for="total_base">Base</label>
            <b-form-input type="text" class="text-right" v-model="policy.basePremium" v-on:blur.native="save(false)" @input="touch($v.policy.basePremium)"></b-form-input>
          </b-col>
          <b-col sm="2">
            <label for="premiumType">Premium Type</label>
            <b-form-select :options="premiumTypes" v-model="policy.premiumType" @input="touch($v.policy.premiumType)"></b-form-select>
          </b-col>
          <b-col sm="2">
            <label for="paymentType">Payment Type</label>
            <b-form-select :options="paymentTypes" v-model="policy.paymentType" @input="touch($v.policy.paymentType)"></b-form-select>
          </b-col>
          <b-col sm="2">
            <label for="paymentSource">Payment Source</label>
            <b-form-select :options="paymentSources" v-model="policy.paymentSource" @input="touch($v.policy.paymentSource)"></b-form-select>
          </b-col>
          <b-col sm="2" v-if="policy.paymentSource == 'new_money'">
            <label for="newMoneyYear">New Money Thru Year</label>
            <b-form-select :options="newMoneyYears" v-model="policy.newMoneyYears" @input="touch($v.policy.newMoneyYears)"></b-form-select>
          </b-col>
          <b-col sm="2">
            <label for="fua_thru_year">PUAR Thru Year</label>
            <b-form-select :options="puarYears" v-model="policy.puarYear" @input="touch($v.policy.puarYear)"></b-form-select>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col sm="2">
            <label for="loanAmount">Loan Rate</label>
            <b-input-group class="rate">
              <b-input type="text" placeholder="" v-model="policy.loanInterestRate" v-on:blur.native="save(false)" :state="state($v.policy.loanInterestRate)" @input="touch($v.policy.loanInterestRate)"></b-input>
              <b-input-group-append><b-input-group-text>%</b-input-group-text></b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm="2" v-if="policy.type && policy.type.canBorrow">
            <label for="loanAmount">Existing Cash Value</label>
            <b-form-input type="text" class="text-right" v-model="policy.cashValue" disabled></b-form-input>
          </b-col>
          <b-col sm="2" v-if="policy.type && policy.type.canBorrow">
            <label for="loanAmount">Existing Loan Amount</label>
            <b-form-input type="text" class="text-right" v-model="policy.loanAmount" disabled></b-form-input>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="4">
        <b-table :small="true" hover :items="illustration" :fields="illustrationFields" @row-clicked="editPolicy()">
          <template v-slot:cell(premium)="data">
            <dollars :value="data.value"></dollars>
          </template>
          <template v-slot:cell(cashValue)="data">
            <dollars :value="data.value"></dollars>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <import-modal :index="index" @uploaded="uploaded"></import-modal>
    <edit-illustration-modal :index="index" @uploaded="uploaded" @closed="refresh"></edit-illustration-modal>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from "vuelidate/lib/validators"
import ImportModal from './Import'
import EditIllustrationModal from './EditIllustration'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import Dollars from '@/components/text/Dollars'
import axios from '@/axios'

export default {
  props: [ 'index', 'policy' ],
  components: {
    Dollars,
    EditIllustrationModal,
    flatPickr,
    ImportModal
  },
  data: () => {
    return {
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true,
      },
      illustrationFields: [
        { key: 'year', label: 'Policy Year' },
        { key: 'premium', label: 'Premium', class: 'text-right' },
        { key: 'cashValue', label: 'Cash Value', class: 'text-right' },
      ],
      saving: false,
      contacts: [],
      contactOptions: [],
      premiumTypes: [
        { value: null, text: 'Lifetime' },
        { value: 8, text: '8' },
        { value: 7, text: '7' },
        { value: 6, text: '6' },
        { value: 5, text: '5' },
        { value: 4, text: '4' },
        { value: 3, text: '3' },
        { value: 2, text: 'Two Pay' },
        { value: 1, text: 'Single Pay' },
      ],
      paymentTypes: [
        { value: 'annual', text: 'Annual' },
        // { value: 'semi-annual', text: 'Semi-Annual' },
        // { value: 'quarterly', text: 'Quarterly' },
        { value: 'monthly', text: 'Monthly' }
      ],
      paymentSources: [
        { value: 'cash_values', text: 'Available Cash Values' },
        { value: 'new_money', text: 'New Money' },
      ],
      newMoneyYears: [
        { value: null, text: `All Years` },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' }
      ],
      puarYears: [
        { value: null, text: `Don't Drop` },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentStrategy: 'global/currentStrategy',
      insuranceCompanies: 'global/insuranceCompanies'
    }),
    illustration: function () {

      return this.policy.illustration.map((illustrationYear) => {
        return {
          year: illustrationYear.year > 0 ? illustrationYear.year : '--',
          premium: illustrationYear.premium,
          cashValue: illustrationYear.cashValue
        }
      })
    },
    insuranceCompanyOptions: function () {
      return this.insuranceCompanies.map(o => ({ value: o.id, text: o.name }))
    }
  },
  methods: {
    refresh () {
      this.$store.dispatch('global/getStrategy', {
        id: this.currentStrategy.id
      })
    },
    importPolicy () {
      this.$bus.$emit(`modal:importPolicy-${this.index}`, this.policy)
    },
    editPolicy () {
      this.$bus.$emit(`modal:editPolicy-${this.index}`, this.policy)
    },
    uploaded (policy) {
      this.$emit('synced', { policy })
    },
    sync (policy) {
      if (policy.existing) {
        let sourcePolicy = policy.sourcePolicy
        this.$api.policies.update({ id: policy.id }, { loanAmount: sourcePolicy.loanAmount, cashValue: sourcePolicy.cashValue })
          .then(() => {
            this.$emit('synced', { policy: this.policy })
          })
          .catch(() => {
            this.$snotify.error('Error Syncing Policy')
          })
      }
    },
    touch (vField) {
      this.existingClients = []
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    updateBase () {
      if (this.policy.annualPremium > 0 && this.policy.basePremium <= 0.0) {
        this.policy.basePremium = this.policy.annualPremium * 0.4
      }
    },
    async save (calculatePremiums, element) {
      if (this.$v.$anyDirty && !this.$v.$invalid) {
        this.$v.$reset()
        this.saving = true

        try {
          const { data } = await this.$api.policies.update({ id: this.policy.id }, this.policy)

          if (calculatePremiums) {
            await this.$api.policies.calculatePremiums({ id: this.policy.id }, this.policy)
          }

          if (element && element.target) {
            element.target.className += ' saved'
            setTimeout(() => {
              element.target.className = 'text-right form-control'
            }, 2000)
          }
          this.saving = false
          this.$emit('saved', { policy: this.policy })
        } catch (err) {
          this.$snotify.error('Error Saving Policy')
          this.saving = false
        }
      }
    },
    async deletePolicy () {
      if (confirm("Are you sure?")) {
        await axios.delete(`/v1/strategies/${this.$route.params.strategyId}/policies/${this.policy.id}`)
        this.$emit('delete', { policy: this.policy })
      }
    },
    async fetchContacts () {
      try {
        const { data } = await this.$api.contacts.clientContacts({ clientId: this.$route.params.id })
        this.contacts = data
        this.contactOptions = [
          { value: null, text: 'Un-Attached' }
        ]
        data.forEach(o => this.contactOptions.push({ value: o.id, text: `${o.firstName} ${o.lastName} (${o.age || 'No Birthday Set'})` }))
      } catch (err) {
        console.log(err)
      }
    },
  },
  watch: {
    'policy.contactId': {
      handler () {
        this.save()
      }
    },
    'policy.insuranceCompanyId': {
      handler () {
        this.save()
      }
    },
    'policy.startedAt': {
      async handler () {
        await this.save(false)
        this.refresh()
      }
    },
    'policy.premiumType': {
      handler () {
        this.save()
      }
    },
    'policy.paymentType': {
      handler () {
        this.save()
      }
    },
    'policy.paymentSource': {
      handler () {
        this.save(true)
      }
    },
    'policy.newMoneyYears': {
      handler () {
        this.save(true)
      }
    },
    'policy.puarYear': {
      handler () {
        this.save()
      }
    }
  },
  created () {
    this.fetchContacts()
  },
  validations: {
    policy: {
      annualPremium: {
        required
      },
      contactId: {},
      insuranceCompanyId: {},
      startedAt: {},
      loanInterestRate: {},
      name: {},
      startYear: {},
      premiumType: {},
      paymentType: {},
      paymentSource: {},
      newMoneyYears: {},
      basePremium: {},
      startAge: {},
      puarYear: {},
      seasoned: {},
      loanAmount: {},
      years: {
        $each: {}
      }
    }
  }
}
</script>

<style>
  h4 {
    margin-bottom: 0px;
  }
  hr {
    margin-top: 0.5em;
  }
  .date.form-control[readonly] {
    background-color: #ffffff;
  }

  .saved {
    animation: pulse-animation 2s normal;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(76, 175, 80, 0.8);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
    }
  }
</style>
