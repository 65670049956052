<template>
  <b-modal title="Link Existing Policy" size="md" v-model="active">
    <b-form-select :options="policies" v-model="policyId"></b-form-select>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="policyId === null" variant="success" @click="save()">
        Link Policy
      </b-btn>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'LinkExistingPolicyModal',
  data () {
    return {
      active: false,
      policies: [],
      policyId: null
    }
  },
  methods: {

    show (policies) {
      this.policies = []
      this.policies.push({ value: null, text: 'Select Policy...', disabled: true })

      policies.forEach((policy) => {
        this.policies.push({ value: policy.id, text: `${policy.name} - ${policy.type.name} $${policy.faceValue}` })
      })
      this.active = true
    },
    save () {
      this.$emit('save', this.policyId)
      this.active = false
    }
  },
  created () {
    this.$bus.$on('modal:linkExistingPolicy', (policies) => {
      this.show(policies);
    })
  }
}
</script>
