<template>
  <b-row>
    <b-col cols="12" sm="12">
      <b-card v-if="policies.length == 0">
        <template slot="header">
          Policy Design
        </template>
        <b-row>
          <b-col sm="12">
            No Policies have been added to this strategy.  <b-button size="sm" variant="success" @click="addPolicy()">Add New Policy</b-button> <b-button v-if="existingPolicies.length > 0" class="ml-2" size="sm" variant="primary" @click="linkExistingPolicy">Select from Existing</b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-row v-if="policies.length > 0">
        <b-col sm="10">
          <policy v-for="(policy, index) in policies" v-bind:key="policy.id" :policy="policy" :index="index" @delete="policyDeleted" @synced="policySynced" @saved="policySaved"></policy>
        </b-col>
        <b-col sm="2">
            <b-card>
              <template slot="header">
                Loan Sequence
              </template>
              <b-card-body>
                <table class="table b-table table-drag-n-drop table-sm">
                  <thead>
                    <tr>
                      <th aria-colindex="1" class=""></th>
                      <th aria-colindex="2" class="">Policy</th>
                      <th aria-colindex="3" class=""></th>
                    </tr>
                  </thead>
                  <draggable tag="tbody" v-model="loanSequence" @end="saveSort()">
                    <tr v-for="policy in loanSequence" :key="policy.id" :class="{ 'disabled': !policy.enabled }">
                      <td aria-colindex="1" class="sort"><i class="fa fa-reorder"></i></td>
                      <td aria-colindex="2" class="name">{{ policy.name }}</td>
                      <td aria-colindex="3">
                        <a href="javascript:void(0)" @click.stop="toggleStatus(policy)">
                          <i class="fa" :class="{ 'fa-toggle-on': policy.enabled, 'fa-toggle-off': !policy.enabled }"></i>
                        </a>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </b-card-body>
            </b-card>
            <b-card>
              <template slot="header">
                Pay Sequence
              </template>
              <b-card-body>
                <table class="table b-table table-drag-n-drop table-sm">
                  <thead>
                    <tr>
                      <th aria-colindex="1" class=""></th>
                      <th aria-colindex="2" class="">Policy</th>
                      <th aria-colindex="3" class=""></th>
                    </tr>
                  </thead>
                  <draggable tag="tbody" v-model="paySequence" @end="saveSort()">
                    <tr v-for="policy in paySequence" :key="policy.id" :class="{ 'disabled': !policy.enabled }">
                      <td aria-colindex="1" class="sort"><i class="fa fa-reorder"></i></td>
                      <td aria-colindex="2" class="name">{{ policy.name }}</td>
                      <td aria-colindex="3">
                        <a href="javascript:void(0)" @click.stop="toggleStatus(policy)">
                          <i class="fa" :class="{ 'fa-toggle-on': policy.enabled, 'fa-toggle-off': !policy.enabled }"></i>
                        </a>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </b-card-body>
            </b-card>
          </b-col>
      </b-row>
      <b-row v-if="policies.length > 0">
        <b-col sm="12">
          <b-button size="sm" variant="success" @click="addPolicy()">Add New Policy</b-button> <b-button v-if="existingPolicies.length > 0" class="ml-2" size="sm" variant="primary" @click="linkExistingPolicy">Select from Existing</b-button>
        </b-col>
      </b-row>
      <link-existing-policy-modal @save="addPolicy"></link-existing-policy-modal>
    </b-col>
  </b-row>
</template>

<script>
import Policy from './components/Policy'
import LinkExistingPolicyModal from '@/components/modals/LinkExistingPolicyModal'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import axios from '@/axios'

export default {
  name: 'Design',
  components: {
    draggable,
    LinkExistingPolicyModal,
    Policy
  },
  data: () => {
    return {
      policies: [],
      loanSequence: [],
      paySequence: []
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'global/currentClient',
      currentStrategy: 'global/currentStrategy',
      insuranceCompanies: 'global/insuranceCompanies'
    }),
    existingPolicies () {
      let allExistingPolicies = []
      if (!this.currentClient || !this.currentStrategy) return []
      const { existingPolicies = [] } = this.currentClient
      const { policies: policiesInStrategy = [] } = this.currentStrategy

      for (const policy of existingPolicies) {
        const alreadyLinked = policiesInStrategy.find((p) => p.id === policy.id)
        if (policy.type.linkable && !alreadyLinked) {
          allExistingPolicies.push(policy)
        }
      }

      return allExistingPolicies
    },
  },
  methods: {
    async toggleStatus(policy) {
      policy.enabled = !policy.enabled
      await this.saveSort()
    },
    async saveSort() {
      const existingOrder = this.policies.map((p) => ({ id: p.id, loanOrder: p.StrategyPolicies.loanOrder, payOrder: p.StrategyPolicies.payOrder }))
      const newOrder = existingOrder.map((p) => {
        const loanIndex = this.loanSequence.findIndex((o) => {
          return o.id === p.id
        })
        const payIndex = this.paySequence.findIndex((o) => {
          return o.id === p.id
        })
        return {
          policyId: p.id,
          loanEnabled: this.loanSequence[loanIndex].enabled,
          loanOrder: loanIndex + 1,
          payEnabled: this.paySequence[payIndex].enabled,
          payOrder: payIndex + 1
        }
      })
      try {
        await axios.put(`/v1/strategies/${this.$route.params.strategyId}/policies/sequence`, { newOrder })
        this.$store.dispatch('global/getStrategy', {
          id: this.$route.params.strategyId
        })
        this.$snotify.success('Policy Sort Order Saved')
      } catch (err) {
        this.$snotify.error('Error Saving Sort Order')
      }
    },
    syncNeeded (policy) {
      if (policy.existing) {
        let sourcePolicy = policy.sourcePolicy
        if (sourcePolicy && (sourcePolicy.loanAmount != policy.loanAmount || sourcePolicy.cashValue != policy.cashValue)) {
          return true
        }
      }
      return false
    },
    sync (policy) {
      if (policy.existing) {
        let sourcePolicy = policy.sourcePolicy
        this.$api.policies.update({ id: policy.id }, { loanAmount: sourcePolicy.loanAmount, cashValue: sourcePolicy.cashValue })
          .then(() => {
            this.$snotify.success('Policy Synced')
            this.$store.dispatch('global/getStrategy', {
              id: this.$route.params.strategyId
            })
          })
          .catch(() => {
            this.$snotify.error('Error Syncing Policy')
          })
      }
    },
    linkExistingPolicy () {
      this.$bus.$emit('modal:linkExistingPolicy', this.existingPolicies)
    },
    async addPolicy (policyId) {
      policyId = policyId || null

      const { organization = null } = this.currentClient
      const countryId = organization ? organization.countryId : 1
      const companies = this.insuranceCompanies.filter((c) => c.countryId === countryId)
      const insuranceCompanyId = companies.length > 0 ? companies[0].id : 1

      if (!policyId) {
        const policy = {
          name: `New Policy ${this.policies.length + 1}`,
          clientId: this.$route.params.id,
          originStrategyId: this.$route.params.strategyId,
          insuranceCompanyId,
          existing: false,
          annualPremium: 0.00,
          basePremium: 0.00,
          premiumType: null,
          paymentType: 'annual',
          puarYear: 4,
          startYear: 1,
          startAge: this.currentClient.primaryContact.age,
          startedAt: this.currentStrategy.startAt,
          seasoned: null
        }

        try {
          const { data: newPolicy } = await axios.post(`/v1/policies`, policy)
          policyId = newPolicy.id
        } catch (err) {
          this.$snotify.error('Error Adding Policy')
        }
      }

      await axios.post(`/v1/strategies/${this.$route.params.strategyId}/policies/${policyId}`)
      this.$store.dispatch('global/getStrategy', {
        id: this.$route.params.strategyId
      })
    },
    policySynced () {
      this.$snotify.success('Policy Synced')
      this.$store.dispatch('global/getStrategy', {
        id: this.$route.params.strategyId
      })
    },
    policySaved ({ policy }) {
      this.$snotify.success('Policy Saved')
      this.mapSequences()
    },
    policyDeleted ({ policy }) {
      let index = this.policies.findIndex((o) => {
        return o.id === policy.id
      })
      if (index >= 0) {
        this.policies.splice(index, 1)
      }
      this.$store.dispatch('global/getStrategy', {
        id: this.$route.params.strategyId
      })
    },
    mapSequences () {
      this.loanSequence = []
      this.paySequence = []
      this.loanSequence = this.policies.map((p) => ({name: p.name, id: p.id, sort: p.StrategyPolicies.loanOrder, enabled: p.StrategyPolicies.loanEnabled })).sort((a, b) => {
        if (a.sort < b.sort) {
          return -1;
        } else if (a.sort > b.sort) {
          return 1;
        } else {
          return 0;
        }
      })
      this.paySequence = this.policies.map((p) => ({name: p.name, id: p.id, sort: p.StrategyPolicies.payOrder, enabled: p.StrategyPolicies.payEnabled })).sort((a, b) => {
        if (a.sort < b.sort) {
          return -1;
        } else if (a.sort > b.sort) {
          return 1;
        } else {
          return 0;
        }
      })
    },
    mapPolicies (policies) {
      this.policies = []

      policies.forEach((policy) => {
        policy.saving = false
        policy.saved = false
        this.policies.push(policy)
      })
      this.policies = this.policies.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        } else {
          return 0;
        }
      })
      this.mapSequences()
    }
  },
  watch: {
    'currentStrategy.policies': {
      handler (policies) {
        this.mapPolicies(policies)
      }
    }
  },
  created () {
    if (this.currentClient.id != this.$route.params.id) {
      this.$store.dispatch('global/getClient', {
        id: this.$route.params.id
      })
    }
    if (this.currentStrategy.id != this.$route.params.strategyId) {
      this.$store.dispatch('global/getStrategy', {
        id: this.$route.params.strategyId
      })
    } else if (this.currentStrategy.policies) {
      this.mapPolicies(this.currentStrategy.policies)
    }
  }
}
</script>

<style>
  div.card-body {
    padding: 0.75em 1.25em 0.75em 1.25em;
  }
  table.table-sm td.numeric, table.table-sm th.numeric {
    text-align: right;
  }
  .table-sm td.delete {
    width: 28px;
  }
  .table-sm td {
    vertical-align: middle;
  }
  .table-sm td.numeric, .table-sm th.numeric {
    text-align: right;
  }
  .disabled .name, .disabled .sort {
    color: #aaa;
  }
</style>
