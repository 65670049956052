<template>
  <tr>
    <td>{{ value.year }}</td>
    <td>{{ value.age }}</td>
    <td><b-form-input ref="premium" type="text" v-model="value.premium" size="sm" class="text-right" @blur.native="save($v.premium, $event)" @keyup.enter.native="keyUp('premium', 'down')" @keyup.down.native="keyUp('premium', 'down')" @keyup.up.native="keyUp('premium', 'up')" @keyup.left.native="keyUp('premium', 'left')" @keyup.right.native="keyUp('premium', 'right')" @input="touch($v.premium)" /></td>
    <td><b-form-input ref="cashValue" type="text" v-model="value.cashValue" size="sm" class="text-right" @blur.native="save($v.cashValue, $event)" @keyup.enter.native="keyUp('cashValue', 'down')" @keyup.down.native="keyUp('cashValue', 'down')" @keyup.up.native="keyUp('cashValue', 'up')" @keyup.left.native="keyUp('cashValue', 'left')" @keyup.right.native="keyUp('cashValue', 'right')" @input="touch($v.cashValue)" /></td>
    <td class="text-right"><dollars :value="value.initialLoanAvailable"></dollars></td>
    <td class="text-right"><dollars :value="value.endOfYearLoanAvailable"></dollars></td>
  </tr>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from '@/axios'
import Dollars from '@/components/text/Dollars'

export default {
  props: [ 'value' ],
  components: {
    Dollars
  },
  name: 'IllustrationYear',
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'global/currentClient',
    }),
  },
  methods: {
    keyUp(ref, direction) {
      this.$emit('changeRow', { year: this.value.year, ref, direction })
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    save (vField, element) {
      if (vField.$dirty) {
        const updates = []
        updates.push({ policyId: this.value.policyId, premium: this.value.premium, cashValue: this.value.cashValue, year: this.value.year })

        axios.put(`/v1/policies/${this.value.policyId}/illustration`, updates)
        .then(response => {
          if (element && element.target) {
            const originalClassName = element.target.className
            element.target.className += ' saved'
            setTimeout(() => {
              element.target.className = originalClassName
            }, 2000)
          }
          vField.$reset()
          this.$emit('changed')
        })
        .catch((err) => {
          this.$snotify.error('Error Saving')
        })
      }
    },
  },
  created () {
    this.$bus.$on(`focus:illustrationYear-${this.value.year}`, ({ ref }) => {
      if (this.$refs[ref]) {
        const field = this.$refs[ref]
        field.focus()
        field.select()
      }
    });
  },
  beforeDestroy () {
    this.$bus.$off(`focus:illustrationYear-${this.value.year}`)
  },
  validations: {
    premium: {},
    cashValue: {}
  },
  watch: {

  }
}
</script>

<style>
  .saved {
    animation: pulse-animation 2s normal;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(76, 175, 80, 0.8);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
    }
  }
</style>
