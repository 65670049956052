<template>
  <b-modal :title="`Policy ${(index + 1)} Illustration`" size="xl" v-model="active">
    <table class="table b-table table-sm">
      <thead>
        <tr>
          <th>Year</th>
          <th>Age</th>
          <th class="text-right">Premium <a href="javascript:void(0)" :disabled="calculatingPremiums" @click="calculatePremiums()"><i class="fa fa-calculator"></i></a></th>
          <th class="text-right">Cash Value <a href="javascript:void(0)" :disabled="calculatingValues" @click="calculateValues()"><i class="fa fa-calculator"></i></a></th>
          <th class="text-right">Initial Loan Available</th>
          <th class="text-right">End Loan Available</th>
        </tr>
      </thead>
      <tbody>
        <illustration-year :value="year" v-for="year in years" :key="year.year" @changeRow="changeRow" @changed="illustrationChange"></illustration-year>
      </tbody>
    </table>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" variant="success" @click="close()">
        Close
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/axios'
import IllustrationYear from './IllustrationYear'

export default {
  name: 'EditIllustrationModal',
  components: {
    IllustrationYear
  },
  props: [ 'index' ],
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    }),
    years () {
      const existingYears = this.illustration
      const extraYears = []

      let minYear = null, maxYear = null
      for (const year of existingYears) {
        if (!minYear || year.year < minYear) {
          minYear = year.year
        }
        if (!maxYear || year.year > maxYear) {
          maxYear = year.year
        }
      }

      if (this.policy) {
        extraYears.push({ policyId: this.policy.id, year: maxYear + 1, premium: '0.00', cashValue: '0.00' })
      }

      return [ ...existingYears, ...extraYears ]
    }
  },
  data () {
    return {
      active: false,
      illustration: [],
      policy: null,
      calculatingPremiums: false,
      calculatingValues: false,
    }
  },
  methods: {
    close () {
      this.active = false
    },
    show (policy) {
      this.active = true
      if (policy) {
        this.policy = { ...policy }
      }
    },
    fetch (policy) {
      if (policy) {
        axios.get(`/v1/policies/${policy.id}/illustration`)
        .then(response => {
          this.illustration = response.data
        })
        .catch((err) => {
          this.$snotify.error('Error Fetch Illustration')
        })
      }
    },
    async calculatePremiums () {
      this.calculatingPremiums = true
      const { id: policyId = null } = this.policy

      if (policyId) {
        axios.put(`/v1/policies/${policyId}/calculatePremiums`)
          .then(response => {
            this.illustrationChange()
          })
          .catch((err) => {
            this.$snotify.error('Error Calculating Premiums')
          })
      }

      this.calculatingPremiums = false
    },
    calculateValues () {
      this.calculatingValues = true
      const { id: policyId = null } = this.policy
      if (policyId) {
        axios.put(`/v1/policies/${policyId}/calculateValues`)
          .then(response => {
            this.illustrationChange()
          })
          .catch((err) => {
            this.$snotify.error('Error Calculating Values')
          })
      }
      this.calculatingValues = false
    },
    changeRow (context) {
      const { year, ref, direction } = context
      switch(direction) {
        case 'left':
        if (ref !== 'premium') {
          this.$bus.$emit(`focus:illustrationYear-${year}`, { ref: 'premium' })
        }
        break;

        case 'right':
        if (ref !== 'cashValue') {
          this.$bus.$emit(`focus:illustrationYear-${year}`, { ref: 'cashValue' })
        }
        break;

        case 'up':
        if (year > 1) {
          this.$bus.$emit(`focus:illustrationYear-${year - 1}`, { ref })
        }
        break;

        case 'down':
        this.$bus.$emit(`focus:illustrationYear-${year + 1}`, { ref })
        break;
      }
    },
    illustrationChange () {
      this.fetch(this.policy)
    }
  },
  beforeDestroy () {
    this.$bus.$off(`modal:editPolicy-${this.index}`)
  },
  watch: {
    'active': {
      handler () {
        if (!this.active) {
          this.$emit('closed')
        }
      }
    },
  },
  created () {
    this.$bus.$on(`modal:editPolicy-${this.index}`, (policy) => {
      this.policy = policy
      this.fetch(policy)
      this.show(policy);
      this.errorMessage = null
      // this.$nextTick(() => { this.$v.$reset() })
    });
  },
  validations: {
  }
}
</script>
